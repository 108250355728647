import React from 'react';
import _ from 'lodash';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Theme,
  Grid,
  Typography,
  TableContainer,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import TableHead from './components/TableHead';
import TableLoading from './components/TableLoading';
import type { TableDataProps } from './interface';
import { getKeys, generateReactKeyByRecord, getMaterialCellPropsFromCol } from './utils';

interface TableDataStyleProps {
  loading: boolean;
}

const useStyles = makeStyles<Theme, TableDataStyleProps>((theme: Theme) => ({
  rootTable: {
    opacity: styleProps => (styleProps.loading ? 0.5 : 1),
  },
  emptyMessageContainer: {
    height: '300px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyMessage: {
    color: '#A0A7AF',
    fontSize: '16px',
  },
}));

function TableData<TRecord = Record<string, any>>(props: TableDataProps<TRecord>) {
  const {
    dataSource,
    columns,
    rowActions,
    tableProps = {},
    tableContainerProps = {},
    emptyMessage = 'NO DATA',
    disableEmptyMessage = false,
    hideTitle = false,
    loading = false,
  } = props;
  const classes = useStyles({ loading });

  const keys = getKeys<TRecord>(columns);
  const hasRowActions = Array.isArray(rowActions);
  const isShowEmptyMessage = !loading && dataSource.length === 0 && !disableEmptyMessage;
  const isShowInitialEmptyContainer = loading && dataSource.length === 0;

  const renderCell = (
    dataRow: TRecord,
    key: string,
    rowIndex: number,
    dataSourceArr: TRecord[],
  ): JSX.Element | string | null => {
    const colByKey = columns.find(col => col.key === key);
    const dataPath = colByKey?.dataPath;
    const render = colByKey?.render;

    const cellProps = {
      ...getMaterialCellPropsFromCol<TRecord>(colByKey),
      style: {
        minWidth: colByKey?.width,
      },
    };
    const cellKey = `${generateReactKeyByRecord<TRecord>(dataRow)}.${key}`;

    const hasDataPath = !_.isNil(dataPath) && dataPath !== '';
    const hasRenderRowFn = _.isFunction(render);

    const value = hasDataPath ? _.get(dataRow, dataPath as string) : dataRow;

    if (hasRenderRowFn) {
      return (
        <TableCell key={cellKey} {...cellProps}>
          {render?.(value, dataRow, rowIndex, dataSourceArr)}
        </TableCell>
      );
    }

    if (hasDataPath) {
      return (
        <TableCell key={cellKey} {...cellProps}>
          {value}
        </TableCell>
      );
    }

    console.error(`Column by ${key} index expected render or dataPath properties`);
    return null;
  };

  const renderTableRow = (dataRow: TRecord, rowIndex: number, dataSourceArr: TRecord[]) => {
    const rowKey = generateReactKeyByRecord<TRecord>(dataRow);

    return (
      <TableRow key={rowKey}>
        {keys.map(key => renderCell(dataRow, key, rowIndex, dataSourceArr))}
      </TableRow>
    );
  };

  const renderEmptyMessage = () => {
    if (!isShowEmptyMessage) {
      return null;
    }

    return (
      <Grid className={classes.emptyMessageContainer}>
        <Typography className={classes.emptyMessage}>{emptyMessage}</Typography>
      </Grid>
    );
  };

  const renderEmptyContainer = () => {
    if (!isShowInitialEmptyContainer) {
      return null;
    }

    return <Box height="300px" />;
  };

  const renderBody = () => {
    return dataSource.map(renderTableRow);
  };

  return (
    <div className={classes.rootTable}>
      <TableContainer {...tableContainerProps}>
        <Box position="relative">
          {loading && <TableLoading />}
          <Table {...tableProps}>
            {!hideTitle && <TableHead columns={columns} hasRowActions={hasRowActions} />}
            <TableBody>{renderBody()}</TableBody>
          </Table>
          {renderEmptyMessage()}
          {renderEmptyContainer()}
        </Box>
      </TableContainer>
    </div>
  );
}

export default TableData;
