import { DurationUnit } from 'luxon';

import { MetricCode, CompanyHeaderMetricCode, MetricPeriod, Report, Source } from 'src/types';
import { convertToListWithDelimiters } from 'src/utils/metric';
import { StatementsReportType } from 'src/graphql';

export const COMPANY_HEADER_METRICS: CompanyHeaderMetricCode[] = [
  MetricCode.SaaSScore,
  MetricCode.EndingMRR,
  MetricCode.AdjustedNetCashBurn,
  MetricCode.CashPosition,
  MetricCode.CashRunwayAccounting,
];

export const INCOME_STATEMENT_REPORT: MetricCode[] = [
  MetricCode.TotalRevenue,
  MetricCode.CostOfGoodsSold,
  MetricCode.GrossProfit,
  MetricCode.GrossMargin,
  MetricCode.TotalOperatingExpenses,
  MetricCode.OtherIncomeAndExpenses,
  MetricCode.NetIncome,
  MetricCode.RevenueGrowthRateMoM,
  MetricCode.YoYRevenueGrowth,
];

export const SALES_AND_MARKETING_REPORT: MetricCode[] = [
  MetricCode.LaborExpenses,
  MetricCode.OtherOperatingExpenses,
  MetricCode.SalesAndMarketingExpenses,
  MetricCode.LaborOfRevenue,
  MetricCode.OtherOperatingExpensesOfRevenue,
  MetricCode.SalesAndMarketingExpensesOfRevenue,
];

export const INFRASTRUCTURE_EXPANSES_REPORT: MetricCode[] = [
  MetricCode.ResearchDevelopmentInfrastructureExpenses,
  MetricCode.GeneralAdministrativeExpenses,
  MetricCode.ResearchDevelopmentExpensesOfRevenue,
  MetricCode.GeneralAdministrativeExpensesOfRevenue,
];

export const BALANCE_SHEET_REPORT: MetricCode[] = [
  MetricCode.CashPosition,
  MetricCode.ChangeInCashAccounting,
  MetricCode.AccountsReceivable,
  MetricCode.TotalCurrentAssets,
  MetricCode.TotalAssets,
  MetricCode.AccountsPayable,
  MetricCode.DeferredRevenue,
  MetricCode.TotalCurrentLiabilities,
  MetricCode.LongTermDebt,
  MetricCode.TotalLiabilities,
  MetricCode.TotalEquity,
  MetricCode.CashRunwayAccounting,
  MetricCode.AdjustedCurrentRatio,
];

export const TAX_REPORT: MetricCode[] = [
  MetricCode.CreditCards,
  MetricCode.ShortTermDebt,
  MetricCode.TaxLiabilities,
  MetricCode.CapitalizedDevelopmentSoftware,
  MetricCode.EquityDistributions,
];

export const STATEMENT_OF_CASH_FLOW_REPORT: MetricCode[] = [
  MetricCode.CashFromOperatingActivities,
  MetricCode.CashFromInvestingActivities,
  MetricCode.CashFromFinancingActivities,
  MetricCode.NetCashChange,
];

export const CASH_BURN_REPORT: MetricCode[] = [
  MetricCode.ChangeInCashAccounting,
  MetricCode.DebtInflows,
  MetricCode.EquityCapitalInflows,
  MetricCode.AdjustedNetCashBurn,
  MetricCode.NetCashBurnOfGrossProfit,
  MetricCode.AdjustedNetCashBurnL3M,
];

export const CASH_REPORT: MetricCode[] = [
  MetricCode.CashPositionPlaid,
  MetricCode.ChangeInCash,
  MetricCode.CashInflows,
  MetricCode.CashOutflows,
  MetricCode.CashRunwayBanking,
];

export const MRR_REPORT: MetricCode[] = [
  MetricCode.BeginningMRR,
  MetricCode.NewMRR,
  MetricCode.ExpansionMRR,
  MetricCode.ChurnMRR,
  MetricCode.ContractionMRR,
  MetricCode.EndingMRR,
  MetricCode.MRRNetGrowthRate,
  MetricCode.MRRNetChurnRate,
  MetricCode.NetRevenueRetentionRate,
  MetricCode.MRRExpansionRate,
  MetricCode.PortionOfMRRFromMRRExpansion,
];

export const CUSTOMERS_REPORT: MetricCode[] = [
  MetricCode.BeginningCustomers,
  MetricCode.NewCustomers,
  MetricCode.ChurnedCustomers,
  MetricCode.EndingCustomers,
  MetricCode.GrossCustomerChurnRate,
  MetricCode.NetCustomersAdd,
];

export const TRENDS_REPORT: MetricCode[] = [
  MetricCode.LTV,
  MetricCode.CAC,
  MetricCode.LTVtoCAC,
  MetricCode.CACPayback,
  MetricCode.ARPU,
  MetricCode.ARPUTrend,
  MetricCode.SaaSQuickRatio,
];

export const UNDERWRITING_PACKAGES_REPORT = [
  INCOME_STATEMENT_REPORT,
  SALES_AND_MARKETING_REPORT,
  INFRASTRUCTURE_EXPANSES_REPORT,
  BALANCE_SHEET_REPORT,
  TAX_REPORT,
  STATEMENT_OF_CASH_FLOW_REPORT,
  CASH_BURN_REPORT,
  CASH_REPORT,
  MRR_REPORT,
  CUSTOMERS_REPORT,
  TRENDS_REPORT,
];

export const WEEKLY_BANK_ACTIVITY_REPORT = [
  [
    MetricCode.CashPositionPlaid,
    MetricCode.ChangeInCashCurrentWeek,
    MetricCode.ChangeInCashTwoWeeks,
    MetricCode.ChangeInCashFourWeeks,
    MetricCode.CashInflows,
    MetricCode.CashOutflows,
    MetricCode.CashRunwayBankingWeekly,
  ],
];

export enum MetricDuration {
  Months = 'months',
  Weeks = 'weeks',
}

export const REPORT_METRIC_LABELS = {
  UnderwritingPackage: 'Underwriting Package',
  WeeklyBankActivity: 'Weekly Bank Activity',
};

export const REPORT_METRIC_TYPES = [
  {
    label: REPORT_METRIC_LABELS.UnderwritingPackage,
    value: convertToListWithDelimiters(UNDERWRITING_PACKAGES_REPORT),
    timeUnit: MetricDuration.Months as DurationUnit,
  },
  {
    label: REPORT_METRIC_LABELS.WeeklyBankActivity,
    value: convertToListWithDelimiters(WEEKLY_BANK_ACTIVITY_REPORT),
    timeUnit: MetricDuration.Weeks as DurationUnit,
  },
];

export const REPORTS_OPTIONS = REPORT_METRIC_TYPES.map((item: Report) => ({
  label: item.label,
  value: item.label,
}));

export const STATEMENTS_REPORT_TYPES = {
  [StatementsReportType.BalanceSheet]: 'Balance Sheet',
  [StatementsReportType.IncomeStatement]: 'Income Statement',
  [StatementsReportType.StatementOfCashFlows]: 'Statement of Cash Flows',
  [StatementsReportType.Banking]: 'Banking',
};

export const ALLOWED_STATEMENT_REPORTS = {
  [Source.Xero]: [StatementsReportType.BalanceSheet, StatementsReportType.IncomeStatement],
  [Source.QBO]: [
    StatementsReportType.BalanceSheet,
    StatementsReportType.StatementOfCashFlows,
    StatementsReportType.IncomeStatement,
  ],
};

export const STATEMENTS_REPORT_OPTIONS = Object.values(STATEMENTS_REPORT_TYPES).map(
  (item: any) => ({
    label: item,
    value: item,
  }),
);

export const SAASSCORE_SHARED_METRICS_WITH_OPTIONS: Record<string, { period: MetricPeriod }> = {
  [MetricCode.CashPositionPlaid]: {
    period: MetricPeriod.Month,
  },
  [MetricCode.CashInflows]: {
    period: MetricPeriod.Month,
  },
  [MetricCode.CashOutflows]: {
    period: MetricPeriod.Month,
  },
  [MetricCode.CashRunwayBanking]: {
    period: MetricPeriod.Month,
  },
  [MetricCode.ChangeInCashTwoWeeks]: {
    period: MetricPeriod.Month,
  },
  [MetricCode.ChangeInCashFourWeeks]: {
    period: MetricPeriod.Month,
  },
};

export const SAASSCORE_SHARED_METRICS: MetricCode[] = Object.keys(
  SAASSCORE_SHARED_METRICS_WITH_OPTIONS,
) as MetricCode[];

// SMB
export const SAASSCORE_METRICS: MetricCode[] = [
  MetricCode.TotalRevenue,
  MetricCode.RevenueGrowthRateMoM,
  MetricCode.GrossMargin,
  MetricCode.NetRevenueRetentionRate,
  MetricCode.MRRNetChurnRate,
  MetricCode.GrossCustomerChurnRate,
  MetricCode.CACPayback,
  MetricCode.CashPosition,
  MetricCode.CashRunwayAccounting,
  MetricCode.NetCashBurnOfGrossProfit,

  ...SAASSCORE_SHARED_METRICS,
];

// ENTERPRISE
export const ENTERPRISE_SAASSCORE_METRICS: MetricCode[] = [
  MetricCode.TotalRevenue,
  MetricCode.YoYRevenueGrowth,
  MetricCode.GrossMargin,
  MetricCode.NetRevenueRetentionRate,
  MetricCode.MRRNetChurnRate,
  MetricCode.GrossCustomerChurnRate,
  MetricCode.CashPosition,
  MetricCode.CashRunwayAccounting,
  MetricCode.NetCashBurnOfGrossProfit,

  ...SAASSCORE_SHARED_METRICS,
];

export const CLIENT_SAASSCORE_METRICS: MetricCode[] = [
  MetricCode.TotalRevenue,
  MetricCode.RevenueGrowthRateMoM,
  MetricCode.GrossMargin,
  MetricCode.NetRevenueRetentionRate,
  MetricCode.MRRNetChurnRate,
  MetricCode.GrossCustomerChurnRate,
  MetricCode.CACPayback,
  MetricCode.CashPosition,
  MetricCode.CashRunwayAccounting,
  MetricCode.NetCashBurnOfGrossProfit,
];

export const SCORE_RESULTS_ORDERED_METRICS: MetricCode[] = [
  MetricCode.TotalRevenue,
  MetricCode.RevenueGrowthRateMoM,
  MetricCode.YoYRevenueGrowth,
  MetricCode.GrossMargin,
  MetricCode.CashPosition,
  MetricCode.CashRunwayAccounting,
  MetricCode.NetCashBurnOfGrossProfit,
];

export const DASHBOARD_METRICS: MetricCode[] = [
  MetricCode.SaaSScore,

  // Revenue Performance
  MetricCode.TotalRevenue,
  MetricCode.RevenueGrowthRateMoM,
  MetricCode.GrossMargin,
  MetricCode.NetRevenueRetentionRate,
  MetricCode.MRRNetChurnRate,
  MetricCode.GrossCustomerChurnRate,

  // SaaS Efficiency
  MetricCode.CACPayback,

  // Capital Resources
  MetricCode.CashPosition,
  MetricCode.CashRunwayAccounting,
  MetricCode.NetCashBurnOfGrossProfit,
];

export const ENTERPRISE_DASHBOARD_METRICS: MetricCode[] = [
  MetricCode.SaaSScore,

  // Revenue Performance
  MetricCode.TotalRevenue,
  MetricCode.RevenueGrowthRateMoM,
  MetricCode.GrossMargin,
  MetricCode.NetRevenueRetentionRate,
  MetricCode.MRRNetChurnRate,
  MetricCode.GrossCustomerChurnRate,

  // Capital Resources
  MetricCode.CashPosition,
  MetricCode.CashRunwayAccounting,
  MetricCode.NetCashBurnOfGrossProfit,
];
export const TIER_TO_STRENGTH: Record<number, string> = {
  1: 'Very Strong',
  2: 'Strong',
  3: 'Above Average',
  4: 'Average',
  5: 'Below Average',
};

export const SCORING_DETAILS_METRICS: MetricCode[] = [
  MetricCode.TotalRevenue,
  MetricCode.RevenueGrowthRateMoM,
  MetricCode.GrossMargin,
  MetricCode.CashPosition,
  MetricCode.CashRunwayAccounting,
  MetricCode.NetCashBurnOfGrossProfit,
];

export const SAASSCORE_PERIODS = [4, 6, 12, 18].map((value: number) => ({
  value,
  label: `Last ${value} months`,
}));

export const MASTER_DATA_METRICS: MetricCode[] = [
  MetricCode.TotalRevenue,
  MetricCode.RevenueGrowthRateMoM,
  MetricCode.GrossMargin,
  MetricCode.NetRevenueRetentionRate,
  MetricCode.MRRNetChurnRate,
  MetricCode.GrossCustomerChurnRate,
  MetricCode.CACPayback,
  MetricCode.CashPosition,
  MetricCode.CashRunwayAccounting,
  MetricCode.NetCashBurnOfGrossProfit,
  MetricCode.YoYRevenueGrowth,
  MetricCode.SaaSScore,
];

export const PORTFOLIO_MONITORING_METRICS: MetricCode[] = [
  MetricCode.CashPosition,
  MetricCode.CashRunwayAccounting,
  MetricCode.ChangeInCashAccounting,
];

export enum MetricSourceLabel {
  AccountingIntegration = 'Accounting Integration',
  BankingData = 'Banking Data',
  ManualInput = 'Manual Input',
  Calculation = 'Calculation',
}

export const SOURCE_TO_LABEL = {
  [Source.QBO]: MetricSourceLabel.AccountingIntegration,
  [Source.Xero]: MetricSourceLabel.AccountingIntegration,
  [Source.Plaid]: MetricSourceLabel.BankingData,
  [Source.Stripe]: MetricSourceLabel.BankingData,
  [Source.Calculated]: MetricSourceLabel.Calculation,
  [Source.Manual]: MetricSourceLabel.ManualInput,
};
