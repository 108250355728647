import * as R from 'ramda';
import pluralize from 'pluralize';

import { Nullable } from 'src/types';
import { Format, Locale } from 'src/constants';
import { isNumber } from 'src/utils/helpers';

export function roundNumber(value: Nullable<number | string>, fractionDigits = 1): number | null {
  if (R.isNil(value)) {
    return null;
  }

  return R.compose(
    R.ifElse(
      R.is(Number),
      R.compose(Number.parseFloat, R.invoker(1, 'toFixed')(fractionDigits)),
      R.compose(Number.parseFloat, R.invoker(1, 'toFixed')(fractionDigits), Number.parseFloat),
    ),
  )(value);
}

export function randomNumber(min: number, max: number, fractionDigits = 1): number | null {
  const rand = min - 0.5 + Math.random() * (max - min + 1);
  return roundNumber(rand, fractionDigits);
}

export function subtractNumbers(n1: number | null, n2: number | null): number | null {
  if (R.isNil(n1)) {
    return null;
  }

  if (R.isNil(n2)) {
    return null;
  }

  return R.compose(roundNumber, R.subtract(n1))(n2);
}

export function sumNumbers(n1: number, n2: number): number | null {
  if (R.isNil(n1)) {
    return null;
  }

  if (R.isNil(n2)) {
    return null;
  }

  return R.compose(roundNumber, R.add(n1))(n2);
}

export function isNegative(value: number | null | undefined): boolean {
  if (R.isNil(value)) {
    return false;
  }

  return R.and(R.is(Number, value), R.lt(value, 0));
}

export function convertToPercentage(value: number | string | null): number | null {
  if (R.isNil(value)) {
    return null;
  }

  if (typeof value === 'string') {
    return R.multiply(parseFloat(value), 100);
  }

  return R.multiply(value, 100);
}

export function convertFromPercentage(value: number | string | null): number | null {
  if (R.isNil(value)) {
    return null;
  }

  if (typeof value === 'string') {
    return R.divide(parseFloat(value), 100);
  }

  if (value !== 0) {
    return R.divide(value, 100);
  }

  return 0;
}

export const convertIfPercentages = (
  value: number | null,
  format: Format | null | undefined,
): number | null => {
  return format === Format.Percent && isNumber(value)
    ? (convertFromPercentage(value) as number)
    : value;
};

export function calculatePercentNumberOfNumber(
  halfNumber: Nullable<number>,
  number: Nullable<number>,
): number | null {
  if (!Number.isFinite(halfNumber) || !Number.isFinite(number) || number === 0) {
    return null;
  }

  return R.divide(halfNumber as number, number as number) * 100;
}

export function numberFormatter(
  value: number | null,
  format: Format | null,
): number | string | null {
  if (R.isNil(format) || R.isNil(value)) {
    return null;
  }

  switch (format) {
    case Format.Percent: {
      return formatDecimalFractionToPercentage(value);
    }

    case Format.Money: {
      return formatNumberToCurrency(value);
    }

    case Format.Month: {
      return formatNumberToMonth(value);
    }

    case Format.Ratio: {
      return formatNumberToRatio(value);
    }

    default: {
      return value;
    }
  }
}

export function formatNumberToCurrency(n: number): string | number {
  if (R.isNil(window.Intl)) {
    return n;
  }

  return new Intl.NumberFormat(Locale.EN, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(n);
}

export function formatDecimalFractionToPercentage(n: number): string | number {
  if (R.isNil(window.Intl)) {
    return n;
  }

  return new Intl.NumberFormat(Locale.EN, {
    style: 'percent',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  }).format(n);
}

export function formatNumberToMonth(n: number): string | null {
  if (R.isNil(n)) {
    return null;
  }

  return pluralize('month', Math.round(n), true);
}

export function formatNumberToRatio(n: number): string | null {
  if (R.isNil(n)) {
    return null;
  }

  return `${n}x`;
}

export function parseStringToFloat(str: string): number | null {
  if (R.isNil(str)) {
    return null;
  }

  if (Number.isNaN(str) && !Number.isFinite(Number(str))) {
    return null;
  }

  return parseFloat(str);
}

export function getFractionCount(number: number): number {
  return (number % 1).toString().length;
}

export function fixNumberAndTruncateZeros(number: number, fractionDigits = 2): number {
  return parseFloat(number.toFixed(fractionDigits));
}

export function leaveFourDigitsInFraction(value: number): number {
  return getFractionCount(value) > 4 ? fixNumberAndTruncateZeros(value, 4) : value;
}
