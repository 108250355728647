import { CovenantListQuery, CovenantValuesListQuery, Covenant, CovenantValue } from 'src/graphql';

export type CovenantForFormType = {
  [key: string]: {
    value: string | undefined | null;
    covenantId: string | undefined | null;
    covenantValueId: string | undefined | null;
    isEnabled: boolean | undefined | null;
  };
};

export const convertCovenantDataForForm = (
  covenantsData: CovenantListQuery | undefined,
  covenantValuesData: CovenantValuesListQuery,
): CovenantForFormType => {
  const covenantValues: Array<CovenantValue> = covenantValuesData?.covenantValuesList?.items;
  const covenants: Array<Covenant> = covenantsData?.covenantsList?.items || [];

  const convertedCovenants = covenants.reduce<CovenantForFormType>(
    (previousCovenant: any, currentCovenant) => {
      const covenantValue = covenantValues.find(covenantValue => {
        return covenantValue?.covenant?.name === currentCovenant.name;
      });

      return {
        ...previousCovenant,
        [currentCovenant.name as string]: {
          value: covenantValue?.value,
          covenantId: currentCovenant.id,
          covenantValueId: covenantValue?.id,
          isEnabled: covenantValue?.isEnabled,
        },
      };
    },
    {},
  );

  return convertedCovenants;
};
