export enum Permission {
  HomeArea = 'HOME_AREA',
  CustomerHomeArea = 'CUSTOMER_HOME_AREA',
  SettingsArea = 'SETTINGS_AREA',

  AdjustedValueEdit = 'ADJUSTED_VALUE_EDIT',
  AdjustedValueDelete = 'ADJUSTED_VALUE_DELETE',

  ClientPortalDashboardArea = 'CLIENT_PORTAL_DASHBOARD_AREA',
  ClientPortalInboxArea = 'CLIENT_PORTAL_INBOX_AREA',
  ClientPortalSaaSStoryArea = 'CLIENT_PORTAL_SAAS_STORY_AREA',
  ClientPortalScoreAndMetricsArea = 'CLIENT_PORTAL_SCORE_AND_METRICS_AREA',
  ClientPortalDocumentsArea = 'CLIENT_PORTAL_DOCUMENTS_AREA',
  ClientPortalUnderwritingPackageArea = 'CLIENT_PORTAL_UNDERWRITING_PACKAGE_AREA',
  ClientPortalIntegrationsArea = 'CLIENT_PORTAL_INTEGRATIONS_AREA',
  ClientPortalUsersArea = 'CLIENT_PORTAL_USERS_AREA',
  ClientPortalFacilityAndDrawsArea = 'CLIENT_PORTAL_FACILITY_AND_DRAWS_AREA',

  LoanApplicationsChangeStage = 'LOAN_APPLICATIONS_CHANGE_STAGE',
  LoanApplicationsAsignTo = 'LOAN_APPLICATIONS_ASIGN_TO',
  LoanApplicationsDelete = 'LOAN_APPLICATIONS_DELETE',
  LoanApplicationsEditInfo = 'LOAN_APPLICATIONS_EDIT_INFO',
  LoanApplicationsEditSaaSStory = 'LOAN_APPLICATIONS_EDIT_SAAS_STORY',
  LoanApplicationsEditMetrics = 'LOAN_APPLICATIONS_EDIT_METRICS',
  LoanApplicationsAddFacility = 'LOAN_APPLICATIONS_ADD_FACILITY',
  LoanApplicationsAddDraw = 'LOAN_APPLICATIONS_ADD_DRAW',
  LoanApplicationsEditDraw = 'LOAN_APPLICATIONS_EDIT_DRAW',
  LoanApplicationsDeleteDraw = 'LOAN_APPLICATIONS_DELETE_DRAW',
  LoanApplicationsChangeFacilityActive = 'LOAN_APPLICATIONS_CHANGE_ACTIVE',
  LoanApplicationsChangeDrawActive = 'LOAN_APPLICATIONS_CHANGE_DRAW_ACTIVE',
  LoadApplicationsArea = 'LOAN_APPLICATIONS_AREA',
  LoanApplicationDelete = 'LOAN_APPLICATION_DELETE',

  PortfolioCompaniesAddCompany = 'PORTFOLIO_COMPANIES_ADD_COMPANY',
  PortfolioCompaniesChangeMonitoringStatus = 'PORTFOLIO_COMPANIES_CHANGE_MONITORING_STATUS',
  PortfolioCompaniesDelete = 'PORTFOLIO_COMPANIES_DELETE',
  PortfolioCompaniesEditInfo = 'PORTFOLIO_COMPANIES_EDIT_INFO',
  PortfolioCompaniesEditSaaSScore = 'PORTFOLIO_COMPANIES_EDIT_SAAS_SCORE',
  PortfolioCompaniesEditMetrics = 'PORTFOLIO_COMPANIES_EDIT_METRICS',
  PortfolioCompaniesAddFacility = 'PORTFOLIO_MONITORING_ADD_FACILITY',
  PortfolioCompaniesArea = 'PORTFOLIO_COMPANIES_AREA',
  PortfolioMonitoringArea = 'PORTFOLIO_MONITORING_AREA',

  PortfolioOverviewArea = 'PORTFOLIO_OVERVIEW_AREA',

  CashMonitoringArea = 'CASH_MONITORING_AREA',

  TeamInvite = 'TEAMMATE_INVITE',
  TeamResendInvite = 'TEAMMATE_RESEND_INVITE',
  TeamCancelInvite = 'TEAMMATE_CANCEL_INVITE',
  TeamDelete = 'TEAMMATE_DELETE',
  TeammateEdit = 'TEAMMATE_EDIT',

  UsersInvite = 'USERS_INVITE',
  UsersDelete = 'USERS_DELETE',
  UserEdit = 'USER_EDIT',
  UsersArea = 'USERS_AREA',

  MasterData = 'MASTER_DATA_AREA',

  SupportLink = 'SUPPORT_LINK',
}
