import * as R from 'ramda';
import { FieldMetaState } from 'react-final-form';

export const hasFieldError = (meta: FieldMetaState<any> = {}): boolean => {
  const { submitError, dirtySinceLastSubmit, error, touched } = meta;

  return ((!!error && !R.isEmpty(error)) || !!submitError) && !!touched && !dirtySinceLastSubmit;
};

export const getFieldError = (meta: FieldMetaState<any> = {}): string | null => {
  if (hasFieldError(meta)) {
    const { submitError, error } = meta;

    const errorToShow = error || submitError;

    if (Array.isArray(errorToShow)) {
      return errorToShow.join(' ');
    }

    return errorToShow || null;
  }

  return null;
};
