export enum CovenantType {
  AdjustedNetCashBurnL3MCovenant = 'AdjustedNetCashBurnL3MCovenant',
  MinCashPosition = 'MinCashPosition',
  MinCashRunway = 'MinCashRunway',
  CumulativeCashReceipts = 'CumulativeCashReceipts',
  MinCashAsPercentOfDrawsTaken = 'MinCashAsPercentOfDrawsTaken',
  CumulativeAdvanceRate = 'CumulativeAdvanceRate',
  ActualVsExpectedRevenue = 'ActualVsExpectedRevenue',
  AdditionalCovenant = 'AdditionalCovenant',
}
