import React from 'react';
import { Paper, Grid, List, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Switch, Route, Redirect, useParams } from 'react-router-dom';
import { SecondaryNavItem } from 'src/app-builder';

import { PortfolioCompanyProvider } from 'src/providers';
import { BackNavItem } from 'src/components';
import { InboxMessagesIcon } from 'src/shared/inboxMessages';
import {
  PortfolioCompanyDashboardPage,
  PortfolioCompanyDocumentsPage,
  PortfolioCompanyHeader,
  PortfolioCompanySaaSStoryPage,
  PortfolioCompanyFacilityAndDrawsPage,
  PortfolioCompanyNotesPage,
  PortfolioCompanyScoreAndMetricsPage,
  PortfolioCompanyUnderwritingPackagePage,
  PortfolioCompanyTeamPage,
  PortfolioCompaniesIntegrationsPage,
  PortfolioCompaniesStatementsPage,
  PortfolioCompaniesInboxPage,
} from './index';
import { useInboxMessagesCount, usePortfolioCompany } from 'src/hooks';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    height: '100%',
    width: '100%',
  },
  content: {
    flex: 1,
    display: 'flex',
    height: '100%',
    overflow: 'auto',
  },
  sidebar: {
    borderRight: `1px solid ${theme.palette.divider}`,
    overflow: 'scroll',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',

    '&::-webkit-scrollbar ': {
      display: 'none',
    },
  },
  fullHeight: {
    height: '100%',
  },
  main: {
    height: 'calc(100% - 98px)',
  },
}));

interface PortfolioCompanyContentProps {
  portfolioCompanyId: string;
}

const PortfolioCompanyPageContent: React.FC<PortfolioCompanyContentProps> = ({
  portfolioCompanyId,
}) => {
  const classes = useStyles();

  const { data: portfolioCompany } = usePortfolioCompany();
  const { messagesCount, setCompanyId } = useInboxMessagesCount();

  setCompanyId(portfolioCompany?.company?.id as string);

  return (
    <Grid container spacing={2} className={classes.fullHeight} direction="column" wrap="nowrap">
      <Grid container item>
        <PortfolioCompanyHeader />
      </Grid>

      <Grid container item className={classes.main}>
        <Paper className={classes.paper}>
          <Grid container direction="row" className={classes.fullHeight} wrap="nowrap">
            <Grid item className={classes.sidebar}>
              <List>
                <BackNavItem to="/portfolio-companies" />
                <SecondaryNavItem
                  to={`/portfolio-companies/${portfolioCompanyId}/dashboard`}
                  icon="ViewQuilt"
                  title="Dashboard"
                />
                <SecondaryNavItem
                  to={`/portfolio-companies/${portfolioCompanyId}/saas-story`}
                  icon="Info"
                  title="SaaSStory"
                />
                <SecondaryNavItem
                  to={`/portfolio-companies/${portfolioCompanyId}/score-and-metrics`}
                  icon="MyLocation"
                  title="SaaSScore &amp; Key Metrics"
                />
                <SecondaryNavItem
                  to={`/portfolio-companies/${portfolioCompanyId}/underwriting-package`}
                  icon="MonetizationOn"
                  title="Underwriting Package"
                />
                <SecondaryNavItem
                  to={`/portfolio-companies/${portfolioCompanyId}/statements`}
                  icon="InsertChart"
                  title="Statements"
                />
                <SecondaryNavItem
                  to={`/portfolio-companies/${portfolioCompanyId}/facility-and-draws`}
                  icon="Assignment"
                  title="Facility &amp; Draws"
                />
                <SecondaryNavItem
                  to={`/portfolio-companies/${portfolioCompanyId}/documents`}
                  icon="FileCopy"
                  title="Documents"
                />
                <SecondaryNavItem
                  to={`/portfolio-companies/${portfolioCompanyId}/notes`}
                  icon="Description"
                  title="Notes"
                />
                <SecondaryNavItem
                  to={`/portfolio-companies/${portfolioCompanyId}/integrations`}
                  icon="OfflineBoltRounded"
                  title="Integrations"
                />
                <SecondaryNavItem
                  to={`/portfolio-companies/${portfolioCompanyId}/team`}
                  icon="Group"
                  title="Team"
                />
                <SecondaryNavItem
                  to={`/portfolio-companies/${portfolioCompanyId}/inbox`}
                  icon={<InboxMessagesIcon messagesCount={messagesCount} />}
                  title="Inbox"
                />
              </List>
            </Grid>

            <Grid item className={classes.content}>
              <Switch>
                <Route
                  path={`/portfolio-companies/:id/dashboard`}
                  component={PortfolioCompanyDashboardPage}
                />
                <Route
                  path={`/portfolio-companies/:id/saas-story`}
                  component={PortfolioCompanySaaSStoryPage}
                />
                <Route
                  path={`/portfolio-companies/:id/score-and-metrics`}
                  component={PortfolioCompanyScoreAndMetricsPage}
                />
                <Route
                  path={`/portfolio-companies/:id/underwriting-package`}
                  component={PortfolioCompanyUnderwritingPackagePage}
                />
                <Route
                  path={`/portfolio-companies/:id/statements`}
                  component={PortfolioCompaniesStatementsPage}
                />
                <Route
                  path={`/portfolio-companies/:id/facility-and-draws`}
                  component={PortfolioCompanyFacilityAndDrawsPage}
                />
                <Route
                  path={`/portfolio-companies/:id/documents`}
                  component={PortfolioCompanyDocumentsPage}
                />
                <Route
                  path={[
                    `/portfolio-companies/:id/notes/:noteId`,
                    `/portfolio-companies/:id/notes`,
                  ]}
                  component={PortfolioCompanyNotesPage}
                />
                <Route
                  path={`/portfolio-companies/:id/team`}
                  component={PortfolioCompanyTeamPage}
                />
                <Route
                  path={`/portfolio-companies/:id/integrations`}
                  component={PortfolioCompaniesIntegrationsPage}
                />
                <Route
                  path={[
                    `/portfolio-companies/:id/inbox/:folder/:messageId`,
                    `/portfolio-companies/:id/inbox/:folder`,
                  ]}
                  component={PortfolioCompaniesInboxPage}
                />

                <Redirect
                  from={`/portfolio-companies/:id/inbox`}
                  to={`/portfolio-companies/:id/inbox/received`}
                />

                <Redirect to={`/portfolio-companies/:id/dashboard`} />
              </Switch>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export const PortfolioCompanyPage: React.FC = () => {
  const { id: portfolioCompanyId }: { id: string } = useParams();

  return (
    <PortfolioCompanyProvider portfolioCompanyId={portfolioCompanyId}>
      <PortfolioCompanyPageContent portfolioCompanyId={portfolioCompanyId} />
    </PortfolioCompanyProvider>
  );
};
