import React from 'react';
import ReactDOM from 'react-dom';
import { Engine } from 'src/app-builder';

import './index.css';
import { STATIC_ROUTES } from './staticRoutes';
import * as serviceWorker from './serviceWorker';

const { REACT_APP_ENDPOINT = '' } = process.env;

ReactDOM.render(
  <Engine uri={REACT_APP_ENDPOINT} routes={STATIC_ROUTES} />,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
