import React from 'react';
import { FieldInputProps, FieldMetaState } from 'react-final-form';
import { hasFieldError, getFieldError } from 'src/app-builder';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  Popover,
  PopoverProps,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import * as icons from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';

function dateToString(date: DateTime | null): string | null {
  if (date && date.isValid) {
    return date.toISO()?.slice(0, 10);
  }
  return null;
}

function stringToDate(date: string | null): DateTime | null {
  return date ? DateTime.fromISO(date) : null;
}

interface DateRangeFieldProps {
  input: FieldInputProps<any>;
  meta: FieldMetaState<any>;
  dateFormat?: string;
  range?: Array<number>;
}

interface RangeItem {
  key: number;
  label: string;
  range: Array<string | null>;
}

const useStyles = makeStyles((theme: Theme) => ({
  mainPopoverRoot: {
    marginTop: theme.spacing(1),
  },
  datePickerPopoverRoot: {
    marginTop: theme.spacing(1),
  },
}));

const DEFAULT_RANGE = [4, 6, 12];

export const DateRangeField: React.FC<DateRangeFieldProps> = ({
  input,
  meta,
  range = DEFAULT_RANGE,
  dateFormat = 'dd/MM/yyyy',
  ...rest
}) => {
  const classes = useStyles();

  const { name, label, value, onChange } = input;

  const error = hasFieldError(meta);
  const helperText = getFieldError(meta);

  const inputRef = React.useRef(null);

  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

  const handlePopoverOpen = React.useCallback((): void => {
    setIsPopoverOpen(true);
  }, [setIsPopoverOpen]);

  const handlePopoverClose = React.useCallback((): void => {
    setIsPopoverOpen(false);
  }, [setIsPopoverOpen]);

  const initialDateFrom = React.useMemo(() => {
    return stringToDate(value[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dateFrom = React.useRef(initialDateFrom);

  const initialDateTo = React.useMemo(() => {
    return stringToDate(value[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dateTo = React.useRef(initialDateTo);

  // Sync ref values, then value changes outside
  React.useEffect(() => {
    if (dateToString(dateFrom.current) !== value[0]) {
      dateFrom.current = stringToDate(value[0]);
    }

    if (dateToString(dateTo.current) !== value[1]) {
      dateTo.current = stringToDate(value[1]);
    }
  }, [value]);

  const handleDateFromChange = React.useCallback(
    (newDate: DateTime | null): void => {
      dateFrom.current = newDate;
      onChange([dateToString(newDate), value[1]]);
    },
    [value, onChange],
  );

  const handleDateToChange = React.useCallback(
    (newDate: DateTime | null): void => {
      dateTo.current = newDate;
      onChange([value[0], dateToString(newDate)]);
    },
    [value, onChange],
  );

  const formattedRange = React.useMemo(() => {
    if (!value[0] && !value[1]) {
      return '';
    }

    const rangeFrom = stringToDate(value[0]);
    const formattedFrom = rangeFrom && rangeFrom.isValid ? rangeFrom.toFormat(dateFormat) : 'Start';

    const rangeTo = stringToDate(value[1]);
    const formattedTo = rangeTo && rangeTo.isValid ? rangeTo.toFormat(dateFormat) : 'Today';

    return `${formattedFrom} — ${formattedTo}`;
  }, [value, dateFormat]);

  const keyboardDatePickerPopoverProps: Partial<PopoverProps> = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    classes: {
      root: classes.datePickerPopoverRoot,
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  };

  const keyboardDatePickerProps: Partial<KeyboardDatePickerProps> = {
    autoOk: true,
    format: dateFormat,
    inputVariant: 'outlined',
    PopoverProps: keyboardDatePickerPopoverProps,
    size: 'small',
    variant: 'inline',
  };

  const relativeRangesList = React.useMemo(() => {
    return range.map(
      (months): RangeItem => {
        const now = DateTime.local();

        const rangeTo = now.endOf('month');
        const rangeFrom = rangeTo.minus({ months: months - 1 }).startOf('month');

        return {
          key: months,
          label: `Last ${months} months`,
          range: [dateToString(rangeFrom), dateToString(rangeTo)],
        };
      },
    );
  }, [range]);

  const handleRelativeRangeClick = React.useCallback(
    (range: Array<string | null>): void => {
      dateFrom.current = stringToDate(range[0]);
      dateTo.current = stringToDate(range[1]);
      onChange(range);

      handlePopoverClose();
    },
    [onChange, handlePopoverClose],
  );

  // Don't use handlePopoverOpen in onFocus on TextField without disableRestoreFocus on Popover,
  // because Unstable_TrapFocus triggers focus again and you can't close popup
  return (
    <React.Fragment>
      <TextField
        error={error}
        focused={isPopoverOpen}
        helperText={helperText}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handlePopoverOpen}>
                <icons.Event color="action" />
              </IconButton>
            </InputAdornment>
          ),
        }}
        inputRef={inputRef}
        label={label}
        name={name}
        onFocus={handlePopoverOpen}
        value={formattedRange}
        variant="outlined"
        {...rest}
      />
      {inputRef.current && (
        <Popover
          anchorEl={inputRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          classes={{
            root: classes.mainPopoverRoot,
          }}
          disableRestoreFocus
          open={isPopoverOpen}
          onClose={handlePopoverClose}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Typography>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <KeyboardDatePicker
                    label="Start date"
                    value={dateFrom.current}
                    onChange={handleDateFromChange}
                    {...keyboardDatePickerProps}
                  />
                </Grid>
                <Grid item xs={6}>
                  <KeyboardDatePicker
                    label="End date"
                    value={dateTo.current}
                    onChange={handleDateToChange}
                    {...keyboardDatePickerProps}
                  />
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <List>
              {relativeRangesList.map(({ key, label, range }) => (
                <ListItem
                  button
                  key={key}
                  selected={range[0] === value[0] && range[1] === value[1]}
                  onClick={() => handleRelativeRangeClick(range)}
                >
                  {label}
                </ListItem>
              ))}
            </List>
          </Typography>
        </Popover>
      )}
    </React.Fragment>
  );
};
