import { EngineProps } from 'src/app-builder';

import {
  ClientPortalDashboardPage,
  ClientPortalSaaSStoryPage,
  ClientPortalScoreAndMetricsPage,
  ClientPortalFacilityAndDrawsPage,
  ClientPortalDocumentsPage,
  ClientPortalSettingsIntegrationsPage,
  ClientPortalSettingsUsersPage,
  ClientPortalInboxPage,
} from 'src/routes/client-portal';

import { LoanApplicationPage, LoanApplicationsTablePage } from 'src/routes/loan-applications';
import { PortfolioMonitoringPage } from 'src/routes/portfolio-monitoring/PortfolioMonitoringPage';
import { PortfolioCompanyPage, PortfolioCompaniesTablePage } from 'src/routes/portfolio-companies';

import { PortfolioOverviewPage } from 'src/routes/portfolio-overview/PortfolioOverview';

import { SettingsUsersPage } from 'src/routes/settings/SettingsUsersPage';

import { CustomerHomePage } from 'src/routes/customer-home';
import { MasterDataPage } from 'src/routes/settings/MasterDataPage/MasterDataPage';
import { CashMonitoringTablePage } from 'src/routes/cash-monitoring/CashMonitoringTablePage';

export const STATIC_ROUTES: EngineProps['routes'] = [
  {
    path: '/portfolio-companies',
    exact: true,
    component: PortfolioCompaniesTablePage,
    location: 'main',
  },
  {
    path: '/portfolio-companies/:id',
    exact: false,
    component: PortfolioCompanyPage,
    location: 'main',
  },
  {
    path: '/portfolio-monitoring',
    exact: true,
    component: PortfolioMonitoringPage,
    location: 'main',
  },
  {
    path: '/loan-applications',
    exact: true,
    component: LoanApplicationsTablePage,
    location: 'main',
  },
  {
    path: '/loan-applications/:id',
    exact: false,
    component: LoanApplicationPage,
    location: 'main',
  },
  {
    path: '/portfolio-overview',
    exact: true,
    component: PortfolioOverviewPage,
    location: 'main',
  },
  {
    path: '/cash-monitoring',
    exact: true,
    component: CashMonitoringTablePage,
    location: 'main',
  },
  {
    path: '/settings/users',
    exact: false,
    component: SettingsUsersPage,
    location: 'settings',
  },
  {
    path: '/settings/master-data',
    exact: false,
    component: MasterDataPage,
    location: 'settings',
  },
  {
    path: '/customer/home',
    exact: false,
    component: CustomerHomePage,
    location: 'main',
  },
  {
    path: '/client-portal/dashboard',
    exact: false,
    component: ClientPortalDashboardPage,
    location: 'main',
  },
  {
    path: '/client-portal/inbox',
    exact: false,
    component: ClientPortalInboxPage,
    location: 'main',
  },
  {
    path: '/client-portal/saas-story',
    exact: false,
    component: ClientPortalSaaSStoryPage,
    location: 'main',
  },
  {
    path: '/client-portal/score-and-metrics',
    exact: false,
    component: ClientPortalScoreAndMetricsPage,
    location: 'main',
  },
  {
    path: '/client-portal/documents',
    exact: false,
    component: ClientPortalDocumentsPage,
    location: 'main',
  },
  {
    path: '/client-portal/facility-and-draws',
    exact: false,
    component: ClientPortalFacilityAndDrawsPage,
    location: 'main',
  },
  {
    path: '/settings/client-portal/integrations',
    exact: false,
    component: ClientPortalSettingsIntegrationsPage,
    location: 'settings',
  },
  {
    path: '/settings/client-portal/users',
    exact: false,
    component: ClientPortalSettingsUsersPage,
    location: 'settings',
  },
];
