import { gql } from '8base-react-sdk';

export const COVENANT_LIST_QUERY = gql`
  query CovenantList {
    covenantsList {
      items {
        id
        name
      }
    }
  }
`;

export const COVENANT_VALUE_LIST_QUERY = gql`
  query CovenantValuesList($filter: CovenantValueFilter) {
    covenantValuesList(filter: $filter) {
      items {
        id
        covenant {
          id
          name
        }
        company {
          id
        }
        value
        isEnabled
      }
    }
  }
`;

export const SET_COVENANT_VALUES = gql`
  mutation CovenantValueCreateMany($data: [CovenantValueCreateManyInput]!) {
    covenantValueCreateMany(data: $data) {
      items {
        id
        value
        isEnabled
      }
    }
  }
`;

export const SET_COVENANT_VALUE = gql`
  mutation CovenantValueCreate($data: CovenantValueCreateInput!) {
    covenantValueCreate(data: $data) {
      id
      isEnabled
    }
  }
`;

export const DELETE_COVENANT_VALUE = gql`
  mutation CovenantValueDelete($filter: CovenantValueKeyFilter) {
    covenantValueDelete(filter: $filter) {
      success
    }
  }
`;

export const UPDATE_COVENANT_VALUE = gql`
  mutation CovenantValueUpdate($data: CovenantValueUpdateInput!, $filter: CovenantValueKeyFilter) {
    covenantValueUpdate(data: $data, filter: $filter) {
      value
      isEnabled
    }
  }
`;
