import logo from 'src/assets/logos/bfc.png';
import logoMark from 'src/assets/logos/bfc-mark.png';
import { Maybe } from 'src/graphql';
import { PRIMARY_COLOR } from 'src/constants/colors';

const {
  REACT_APP_WORKSPACE_ID = '',
  REACT_APP_AUTH_CLIENT_ID = '',
  REACT_APP_AUTH_DOMAIN = '',
  REACT_APP_WORKSPACE_ENV = '',
} = process.env;

export type MainSetting = {
  authClientId: string;
  authDomain: string;
  workspaceId: string;
  roleSwitchEnabled: boolean;
  logo: string;
  logoMark: string;
};

const MAIN_SETTINGS: MainSetting = {
  authClientId: REACT_APP_AUTH_CLIENT_ID,
  authDomain: REACT_APP_AUTH_DOMAIN,
  workspaceId: REACT_APP_WORKSPACE_ID,
  // show role switch only on staging and dev
  roleSwitchEnabled: !(!REACT_APP_WORKSPACE_ENV || REACT_APP_WORKSPACE_ENV === 'Master'),
  logo,
  logoMark,
};

export type ThemeSettings = {
  primaryColor: string;
  secondaryColor: string;
};

const THEME_SETTINGS = {
  primaryColor: PRIMARY_COLOR,
  secondaryColor: PRIMARY_COLOR,
};

export const FIELD_FORMATS = {
  PLAIN: 'plain',
  CHIP: 'chip',
  LINK: 'link',
  MONEY: 'money',
  AVATAR: 'avatar',
};

export type FieldFormat = typeof FIELD_FORMATS[keyof typeof FIELD_FORMATS];

export type AppAreaField = {
  id: string;
  fieldId: string;
  tableId: string | null;
  fieldName: string;
  name: string;
  format: FieldFormat;
  formatOptions: any;
  showByDefault: boolean;
  sortEnabled: boolean;
};

export type AreaSettings = {
  id: string;
  name: string;
  title: Maybe<string>;
  tableId: Maybe<string>;
  path: string;
  icon: string;
  areaType: 'table' | 'custom';
  location: 'main' | 'settings' | 'custom';
  isSystem: boolean;
  position: number;
  code: string;
  fields?: {
    items: Array<AppAreaField>;
  };
  fieldsOrder?: Maybe<Array<string>>;
};

export type AreasSettings = Array<AreaSettings>;

const AREAS_SETTINGS: AreasSettings = [
  {
    id: 'cke46gh8r003007ky7wzq9703',
    name: 'Users',
    title: 'Users',
    tableId: '5e776b9826cbee0007d2cb74',
    path: '/settings/users',
    icon: 'Group',
    areaType: 'table',
    location: 'settings',
    isSystem: true,
    position: 1,
    code: 'USERS',
    fields: {
      items: [
        {
          id: 'cke46gj0k008j08l925vv5ulf',
          fieldId: '5e776b9826cbee0007d2cb89',
          tableId: null,
          fieldName: 'lastName',
          name: 'Last Name',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'cke46gjkd008b08l3edaq94bt',
          fieldId: '5e776b9826cbee0007d2cb80',
          tableId: null,
          fieldName: 'email',
          name: 'Email',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'cke46gjla007r07mi7f7hezhz',
          fieldId: '5e776b9926cbee0007d2cca4',
          tableId: null,
          fieldName: 'roles',
          name: 'Roles',
          format: 'plain',
          formatOptions: {
            labels: {
              appAnalyst: 'Analyst',
              appAdministrator: 'Administrator',
            },
          },
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'cke46gjm2010607jv27nsgho1',
          fieldId: '5e776b9f26cbee0007d2cd5e',
          tableId: null,
          fieldName: 'status',
          name: 'Status',
          format: 'chip',
          formatOptions: {
            colors: {
              active: '#a1cf43',
              inactive: 'error',
              invitationPending: 'info',
            },
            labels: {
              active: 'Active',
              inactive: 'Inactive',
              invitationPending: 'Invitation Pending',
            },
          },
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'cke46gjnr001308lb2wvl23k0',
          fieldId: '5e776b9826cbee0007d2cb86',
          tableId: null,
          fieldName: 'firstName',
          name: 'First Name',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'cke46gjp6000q08l16vvzhw8z',
          fieldId: '5e776b9826cbee0007d2cb77',
          tableId: null,
          fieldName: 'createdAt',
          name: 'Created At',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'cke46gjt300a007l7cbydce5u',
          fieldId: '5e776b9926cbee0007d2cc91',
          tableId: null,
          fieldName: 'avatar',
          name: 'Avatar',
          format: 'avatar',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'cke46gkax01vl07l57mflbpdg',
          fieldId: '5e776b9826cbee0007d2cb7a',
          tableId: null,
          fieldName: 'updatedAt',
          name: 'Updated At',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
      ],
    },
    fieldsOrder: [
      'cke46gjt300a007l7cbydce5u',
      'cke46gjkd008b08l3edaq94bt',
      'cke46gjnr001308lb2wvl23k0',
      'cke46gj0k008j08l925vv5ulf',
      'cke46gjm2010607jv27nsgho1',
      'cke46gjla007r07mi7f7hezhz',
      'cke46gjp6000q08l16vvzhw8z',
      'cke46gkax01vl07l57mflbpdg',
    ],
  },
  {
    id: 'ckeb9uak1003n08jq5rncd0tx',
    name: 'Portfolio Monitoring',
    title: null,
    tableId: '5f1167ed20c46c0008629a82',
    path: '/portfolio-monitoring',
    icon: 'MyLocation',
    areaType: 'custom',
    location: 'main',
    isSystem: false,
    position: 2,
    code: 'PORTFOLIO_MONITORING',
    fields: {
      items: [
        {
          id: 'ckeb9uc33002w07l7ayk50jth',
          fieldId: '5f1167ed20c46c0008629a8c',
          tableId: null,
          fieldName: 'id',
          name: 'ID',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckeb9uc33002x07l7bor57ybm',
          fieldId: '5f1167ed20c46c0008629a8a',
          tableId: null,
          fieldName: 'createdAt',
          name: 'Created At',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckeb9uc33002y07l7aallao8s',
          fieldId: '5f1167ed20c46c0008629a88',
          tableId: null,
          fieldName: 'updatedAt',
          name: 'Updated At',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckeb9uc33002z07l795rcasvh',
          fieldId: '5f1167ed20c46c0008629a84',
          tableId: null,
          fieldName: 'createdBy',
          name: 'Created By',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckeb9uc33003007l77fqy3wmi',
          fieldId: '5f116a3252b6960007245303',
          tableId: null,
          fieldName: 'companyName',
          name: 'Company Name',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckeb9uc33003107l76gfz0c2l',
          fieldId: '5f116a3d598a6800081c5d25',
          tableId: null,
          fieldName: 'loanDate',
          name: 'Loan Date',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckeb9uc33003207l704xzd0zj',
          fieldId: '5f116a490ff356000869997b',
          tableId: null,
          fieldName: 'bankBalance',
          name: 'Bank Balance',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckeb9uc33003307l7cnv08jzn',
          fieldId: '5f116a6552b6960007247b0e',
          tableId: null,
          fieldName: 'monitoringStatus',
          name: 'Monitoring Status',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckeb9uc33003407l75x27dhde',
          fieldId: '5f116a86bf50fd000752ce34',
          tableId: null,
          fieldName: 'accountingDate',
          name: 'Accounting Date',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckeb9uc33003507l7hqdh5thu',
          fieldId: '5f116a8fc9e6610007ef1607',
          tableId: null,
          fieldName: 'merchantDate',
          name: 'Merchant Date',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckeb9uc33003607l7b7tvdc0c',
          fieldId: '5f116aacd5452f000855cee5',
          tableId: null,
          fieldName: 'bankAccountDate',
          name: 'Bank Account Date',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
      ],
    },
    fieldsOrder: [
      'ckeb9uc33002w07l7ayk50jth',
      'ckeb9uc33002x07l7bor57ybm',
      'ckeb9uc33002y07l7aallao8s',
      'ckeb9uc33002z07l795rcasvh',
      'ckeb9uc33003007l77fqy3wmi',
      'ckeb9uc33003107l76gfz0c2l',
      'ckeb9uc33003207l704xzd0zj',
      'ckeb9uc33003307l7cnv08jzn',
      'ckeb9uc33003407l75x27dhde',
      'ckeb9uc33003507l7hqdh5thu',
      'ckeb9uc33003607l7b7tvdc0c',
    ],
  },
  {
    id: 'cketstslq001x08lcc5hjg1pq',
    name: 'Portfolio Companies',
    title: null,
    tableId: '5f1167fa20c46c000862a5e8',
    path: '/portfolio-companies',
    icon: 'Group',

    areaType: 'table',
    location: 'main',
    isSystem: false,
    position: 3,
    code: 'PORTFOLIO_COMPANIES',
    fields: {
      items: [
        {
          id: 'cketsttwp005809jlcxe53ajw',
          fieldId: '5f116ae2c9e6610007ef4912',
          tableId: null,
          fieldName: 'company.name',
          name: 'Company Name',
          format: 'link',
          formatOptions: { to: '/portfolio-companies/<%- record.id %>' },
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckfc6pu9m002707mb1ztbd107',
          fieldId: '5f997619c7dfe40008179e70',
          tableId: '5f96e0dc5c028300076fa3e6',
          fieldName: 'company.facilities.items[0].draws.items[0].amountDeployed',
          name: '$ Deployed',
          format: 'money',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckfc7u5mq004307l3auxygu8r',
          fieldId: '5f63461441f7d000089825f6',
          tableId: '5f56a275c1ae73000889f2ba',
          fieldName: 'company.facilities.items[0].structure',
          name: 'Structure',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckfc90lrs01z307lfchaxezgz',
          fieldId: '5f63583d463fc60008af4dfb',
          tableId: '5f1167fa20c46c000862a5e8',
          fieldName: 'company.owner.lastName',
          name: 'Primary Contact',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckffb6fcf003r07mi934p7po1',
          fieldId: '5f6b27effc65db0007668057',
          tableId: '5f1167fa20c46c000862a5e8',
          fieldName: 'active',
          name: 'Active?',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckffb7n1a003b07jnarus9wdr',
          fieldId: '5f76c745720a9f0008702705',
          tableId: '5f76c3b98440db00071fc1a6',
          fieldName: 'monitoringStatus',
          name: 'Monitoring Status',
          format: 'chip',
          formatOptions: {
            colors: {
              OK: '#009e8a',
              Default: '#EB4336',
              Workout: '#0A6DC4',
              'Watch list': '#F57C00',
              'Incomplete data': '#236B90',
            },
          },
          showByDefault: true,
          sortEnabled: false,
        },
        {
          id: 'ckgw60mxl00bf07jy2vxh4oih',
          fieldId: '5f997602c7dfe40008176d84',
          tableId: '5f96e0dc5c028300076fa3e6',
          fieldName: 'company.facilities.items[0].draws.items[0].fundedDate',
          name: 'Funded Date',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckgw68vje008n08mk580xdu36',
          fieldId: '5f96d706d5cb5d00087b46b6',
          tableId: '5f56a275c1ae73000889f2ba',
          fieldName: 'company.facilities.items[0].amountCommitted',
          name: '$ Committed',
          format: 'money',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckgw6hfq600dp07l3e2uphrtt',
          fieldId: '5f96d784df330b000854ce78',
          tableId: '5f56a275c1ae73000889f2ba',
          fieldName: 'company.facilities.items[0].finalAdvanceRate',
          name: 'Final Advance Date',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckgw6j2t8004y07ic6atm6960',
          fieldId: '5f96d79926c65e0007acb760',
          tableId: '5f56a275c1ae73000889f2ba',
          fieldName: 'company.facilities.items[0].nextAvailableDraw',
          name: 'Next Available Draw',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckh4pw0zl00iw08l12a5m8f7h',
          fieldId: '5f997663c7dfe40008182083',
          tableId: '5f96e0dc5c028300076fa3e6',
          fieldName: 'company.facilities.items[0].draws.items[0].term',
          name: 'Term',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
      ],
    },
    fieldsOrder: [
      'cketsttwp005809jlcxe53ajw',
      'ckffb6fcf003r07mi934p7po1',
      'ckffb7n1a003b07jnarus9wdr',
      'ckfc90lrs01z307lfchaxezgz',
      'ckgw60mxl00bf07jy2vxh4oih',
      'ckfc7u5mq004307l3auxygu8r',
      'ckfc6pu9m002707mb1ztbd107',
      'ckgw68vje008n08mk580xdu36',
      'ckh4pw0zl00iw08l12a5m8f7h',
      'ckgw6hfq600dp07l3e2uphrtt',
      'ckgw6j2t8004y07ic6atm6960',
    ],
  },
  {
    id: 'ckeveh6qb005107l58clq7bjs',
    name: 'Documents',
    title: null,
    tableId: '5f58d09518a065000818bfca',
    path: '/documents',
    icon: 'Adb',

    areaType: 'table',
    location: 'custom',
    isSystem: false,
    position: 1,
    code: 'DOCUMENTS',
    fields: {
      items: [
        {
          id: 'ckeveh7yx02du08ky2tkrelu4',
          fieldId: '5f58d3b117e6850007419d0a',
          tableId: null,
          fieldName: 'file',
          name: 'Document Name',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckeveh7yx02dv08kyeuxfge6t',
          fieldId: '5f58d3c25fff520008e3f0cc',
          tableId: null,
          fieldName: 'description',
          name: 'Description',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckeveh7yx02dx08kya2a66wuu',
          fieldId: '5e776b9826cbee0007d2cb86',
          tableId: '5e776b9826cbee0007d2cb74',
          fieldName: 'createdBy.firstName',
          name: 'Provided By',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckf5fy6tg01cj07jz8y628a1p',
          fieldId: '5f58d09518a065000818bfd0',
          tableId: null,
          fieldName: 'updatedAt',
          name: 'Date & Time',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckgtevq9q009l08lhfncn7qw7',
          fieldId: '5f9935f2b320a700077065f3',
          tableId: '5f58d09518a065000818bfca',
          fieldName: 'private',
          name: 'Private?',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
      ],
    },
    fieldsOrder: [
      'ckeveh7yx02du08ky2tkrelu4',
      'ckf5fy6tg01cj07jz8y628a1p',
      'ckeveh7yx02dx08kya2a66wuu',
      'ckgtevq9q009l08lhfncn7qw7',
    ],
  },
  {
    id: 'ckf5e8uny00a708mogmxi8wjh',
    name: 'Loan Applications',
    title: null,
    tableId: '5f1167453cee2c000739b7af',
    path: '/loan-applications',
    icon: 'Assignment',

    areaType: 'table',
    location: 'main',
    isSystem: false,
    position: 1,
    code: 'LOAN_APPLICATIONS',
    fields: {
      items: [
        {
          id: 'ckf5e8y6j000y07mn9uixcu6f',
          fieldId: '5f58c432395f3100072613cb',
          tableId: '5f1167453cee2c000739b7af',
          fieldName: 'company.name',
          name: 'Applicant Name',
          format: 'link',
          formatOptions: { to: '/loan-applications/<%- record.id %>' },
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckf5e8y6j000z07mnd32kew87',
          fieldId: '5f61f266bf5c700007596a4b',
          tableId: null,
          fieldName: 'stage',
          name: 'Stage',
          format: 'chip',
          formatOptions: {
            colors: {
              Lost: '#EB4336',
              Funded: '#4CAF50',
              Closing: '#9D7DD5',
              Rejected: '#000000',
              'Term Sheet': '#9ABBA0',
              Prospecting: '#009e8a',
              Underwriting: '#0A6DC4',
              'App Completed': '#F57C00',
              'Info Gathering': '#236B90',
              'Structure Presented': '#9D7DD5',
            },
          },
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckf5e8y6j001007mn9l8p22qk',
          fieldId: '5f61f34498efaf00072cb77f',
          tableId: null,
          fieldName: 'createdAt',
          name: 'Account Creation Date',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckf5e8y6j001107mn63odhaux',
          fieldId: '5f61f290b22d2500076f648b',
          tableId: null,
          fieldName: 'contactName',
          name: 'Contact Name',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckf5e8y6j001307mn2efmhc8h',
          fieldId: '5f57588bed3e7300088b99b1',
          tableId: '5f1167fa20c46c000862a5e8',
          fieldName: 'company.capital',
          name: 'Amount Requested',
          format: 'money',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckf5e8y6k001407mn2in6apzv',
          fieldId: '5f96d706d5cb5d00087b46b6',
          tableId: '5f56a275c1ae73000889f2ba',
          fieldName: 'company.facilities.items[0].amountCommitted',
          name: '$ Committed',
          format: 'money',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckf5e8y6k001507mn82j8efs8',
          fieldId: '5f61f2fbb22d2500076fb93b',
          tableId: null,
          fieldName: 'firstDrawProposed',
          name: '1st Draw Proposed',
          format: 'money',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckf5e8y6k001607mn31wpgb3l',
          fieldId: '5f61f2b0fc51e10007928aa0',
          tableId: null,
          fieldName: 'targetCloseDate',
          name: 'Target Close Date',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckgqb2hld00af08l0etza6194',
          fieldId: '5f968e47eeaebe0008b28396',
          tableId: '5f1167453cee2c000739b7af',
          fieldName: 'age',
          name: 'Age',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckgt8007z007007mdb4djcj61',
          fieldId: '5f993d7f6310b700071d7b97',
          tableId: '5f1167fa20c46c000862a5e8',
          fieldName: 'company.saaSScore',
          name: 'SaaSScore',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: false,
        },
        {
          id: 'ckh641t9f001f07k0h57z36n4',
          fieldId: '5e776b9826cbee0007d2cb80',
          tableId: '5e776b9826cbee0007d2cb74',
          fieldName: 'dealTeam',
          name: 'Deal Team',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
      ],
    },
    fieldsOrder: [
      'ckf5e8y6j000y07mn9uixcu6f',
      'ckf5e8y6j001107mn63odhaux',
      'ckf5e8y6j001007mn9l8p22qk',
      'ckgqb2hld00af08l0etza6194',
      'ckf5e8y6j000z07mnd32kew87',
      'ckgt8007z007007mdb4djcj61',
      'ckf5e8y6j001307mn2efmhc8h',
      'ckf5e8y6k001407mn2in6apzv',
      'ckf5e8y6k001507mn82j8efs8',
      'ckf5e8y6k001607mn31wpgb3l',
      'ckh641t9f001f07k0h57z36n4',
    ],
  },
  {
    id: '',
    name: 'Cash Monitoring',
    title: 'Portfolio Overview',
    tableId: '',
    path: '/portfolio-overview',
    icon: 'Visibility',

    areaType: 'custom',
    location: 'main',
    isSystem: false,
    position: 5,
    code: 'PORTFOLIO_OVERVIEW',
    fields: { items: [] },
    fieldsOrder: [],
  },
  {
    id: '',
    name: 'Cash Monitoring',
    title: 'Cash Monitoring',
    tableId: '',
    path: '/cash-monitoring',
    icon: 'MonetizationOn',

    areaType: 'custom',
    location: 'main',
    isSystem: false,
    position: 5,
    code: 'CASH_MONITORING',
    fields: { items: [] },
    fieldsOrder: [],
  },
  {
    id: 'ckfe2362m00lx07l28xig8qxa',
    name: 'Customer Home',
    title: 'Home',
    tableId: '',
    path: '/customer/home',
    icon: 'Home',

    areaType: 'custom',
    location: 'main',
    isSystem: false,
    position: -1,
    code: 'CUSTOMER_HOME',
    fields: { items: [] },
    fieldsOrder: [],
  },
  {
    id: 'ckfzar4s300aw07js5hn2d0xp',
    name: 'Team',
    title: 'Team',
    tableId: '5e776b9826cbee0007d2cb74',
    path: '/portfolio-companies/:id/team',
    icon: 'Group',

    areaType: 'table',
    location: 'custom',
    isSystem: false,
    position: 1,
    code: 'TEAM',
    fields: {
      items: [
        {
          id: 'ckfzar65e01bu07jt5wfp59fh',
          fieldId: '5e776b9826cbee0007d2cb80',
          tableId: '5e776b9826cbee0007d2cb74',
          fieldName: 'email',
          name: 'Email',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckfzar65e01by07jte4uj587c',
          fieldId: '5e776b9826cbee0007d2cb86',
          tableId: '5e776b9826cbee0007d2cb74',
          fieldName: 'firstName',
          name: 'Name',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckg20bvin009t07mf8l8s66zz',
          fieldId: '5f7dbce4687ce6000720aaa1',
          tableId: '5e776b9826cbee0007d2cb74',
          fieldName: 'title',
          name: 'Title',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'ckg29d5v400jr07mech3a84de',
          fieldId: '5e776b9f26cbee0007d2cd5e',
          tableId: '5e776b9826cbee0007d2cb74',
          fieldName: 'status',
          name: 'Status',
          format: 'chip',
          formatOptions: {
            colors: { active: '#a1cf43', inactive: 'error', invitationPending: 'info' },
            labels: {
              active: 'Active',
              inactive: 'Inactive',
              invitationPending: 'Invitation Pending',
            },
          },
          showByDefault: true,
          sortEnabled: true,
        },
        {
          id: 'cki8lgp8e005107l1b3af7mte',
          fieldId: '5e776b9926cbee0007d2cca4',
          tableId: '5e776b9826cbee0007d2cb74',
          fieldName: 'roles',
          name: 'Roles',
          format: 'plain',
          formatOptions: {},
          showByDefault: true,
          sortEnabled: true,
        },
      ],
    },
    fieldsOrder: [
      'ckfzar65e01by07jte4uj587c',
      'ckg20bvin009t07mf8l8s66zz',
      'ckfzar65e01bu07jt5wfp59fh',
      'ckg29d5v400jr07mech3a84de',
      'cki8lgp8e005107l1b3af7mte',
    ],
  },
  {
    id: 'ckko8cype013i07l57gwo2t7w',
    name: 'Client Portal Dashboard',
    title: 'Dashboard',
    tableId: null,
    path: '/client-portal/dashboard',
    icon: 'Home',

    areaType: 'custom',
    location: 'main',
    isSystem: false,
    position: 1,
    code: 'CLIENT_PORTAL_DASHBOARD',
    fields: { items: [] },
    fieldsOrder: [],
  },
  {
    id: 'ckko8cype013i07l57gwo2t7w',
    name: 'Client Portal Inbox',
    title: 'Inbox',
    tableId: null,
    path: '/client-portal/inbox',
    icon: 'MoveToInbox',

    areaType: 'custom',
    location: 'main',
    isSystem: false,
    position: 1,
    code: 'CLIENT_PORTAL_INBOX',
    fields: { items: [] },
    fieldsOrder: [],
  },
  {
    id: 'ckkpaqzj500pz08l7a7pxb2y8',
    name: ' Client Portal SaasStory',
    title: 'SaasStory',
    tableId: null,
    path: '/client-portal/saas-story',
    icon: 'AccountCircle',

    areaType: 'custom',
    location: 'main',
    isSystem: false,
    position: 2,
    code: 'CLIENT_PORTAL_SAAS_STORY',
    fields: { items: [] },
    fieldsOrder: [],
  },
  {
    id: 'ckkpar0rm019f07l9by52cczx',
    name: 'Client Portal Score and Metrics',
    title: 'SaaSScore & Key Metrics',
    tableId: null,
    path: '/client-portal/score-and-metrics',
    icon: 'MyLocation',

    areaType: 'custom',
    location: 'main',
    isSystem: false,
    position: 3,
    code: 'CLIENT_PORTAL_SCORE_AND_METRICS',
    fields: { items: [] },
    fieldsOrder: [],
  },
  {
    id: 'cklm7ec94000209mh0uhxdzpk',
    name: 'Client Portal Documents',
    title: 'Documents',
    tableId: null,
    path: '/client-portal/documents',
    icon: 'FileCopy',

    areaType: 'custom',
    location: 'main',
    isSystem: false,
    position: 4,
    code: 'CLIENT_PORTAL_DOCUMENTS',
    fields: { items: [] },
    fieldsOrder: null,
  },
  {
    id: 'cklm7ehyu000b09mhbhm0h5ac',
    name: 'Client Portal Integrations',
    title: 'Integrations',
    tableId: null,
    path: '/settings/client-portal/integrations',
    icon: 'Group',

    areaType: 'custom',
    location: 'settings',
    isSystem: false,
    position: 5,
    code: 'CLIENT_PORTAL_INTEGRATIONS',
    fields: { items: [] },
    fieldsOrder: ['1'],
  },
  {
    id: 'cklm7ehyu000c09mh4x7i77b7',
    name: 'Client Portal Users',
    title: 'Users',
    tableId: null,
    path: '/settings/client-portal/users',
    icon: 'Group',
    areaType: 'custom',
    location: 'settings',
    isSystem: false,
    position: 6,
    code: 'CLIENT_PORTAL_USERS',
    fields: { items: [] },
    fieldsOrder: ['1'],
  },
  {
    id: 'ckls6ynmm00e407l0dlppcgwq',
    name: 'Client Portal Facility and Draws',
    title: 'Facility & Draws',
    tableId: null,
    path: '/client-portal/facility-and-draws',
    icon: 'Assignment',

    areaType: 'custom',
    location: 'main',
    isSystem: false,
    position: 4,
    code: 'CLIENT_PORTAL_FACILITY_AND_DRAWS',
    fields: { items: [] },
    fieldsOrder: null,
  },
  {
    id: '1',
    name: 'Master Data',
    title: 'Master Data',
    tableId: null,
    path: '/settings/master-data',
    icon: 'PermDataSetting',
    areaType: 'custom',
    location: 'settings',
    isSystem: true,
    position: 2,
    code: 'MASTER_DATA',
  },
];

export const APP_SETTING = {
  ...MAIN_SETTINGS,
  theme: THEME_SETTINGS,
  areas: {
    items: AREAS_SETTINGS,
  },
};
