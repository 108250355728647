import React, { useCallback, useEffect, useRef } from 'react';
import * as R from 'ramda';
import { useQuery } from 'react-apollo';
import { CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import { FormatListBulleted } from '@material-ui/icons';

import { useCurrentUser } from 'src/app-builder';
import { EmptyScreen, SaaSLoanCard } from 'src/components';
import { Header, ScoringChart, ScoringDetails } from './';
import { useCalculateMetrics, useInterval, useMetricsUpdate, useSaaSLoanOptions } from 'src/hooks';

import {
  INTEGRATIONS_QUERY,
  EVENT_RESOLVER_QUERY,
  EventResolverQueryQuery,
  EventResolverQueryQueryVariables,
  EventType,
  UserIntegrationsEntityQuery,
  UserIntegrationsEntityQueryVariables,
} from 'src/graphql';

const useStyles = makeStyles(theme => ({
  saasLoan: {
    marginTop: 20,
  },
  heading: {
    fontSize: 50,
    fontWeight: 400,
    textAlign: 'left',
    marginBottom: 20,
  },
  loanContainer: {
    minHeight: 300,
    paddingBottom: theme.spacing(4),
  },
  loader: {
    color: theme.palette.grey[400],
    marginBottom: 20,
  },
  loaderWrapper: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
}));

interface SaasScoreResultsProps {}

const PLANNED_TIME = 90;
const PROGRESS_LIMIT = 90;

export const SaasScoreResults: React.FC<SaasScoreResultsProps> = () => {
  const classes = useStyles();
  const { user, loading: userLoading } = useCurrentUser();

  const userId = user?.id as string;

  const { data: integrations, loading: integrationsLoading } = useQuery<
    UserIntegrationsEntityQuery,
    UserIntegrationsEntityQueryVariables
  >(INTEGRATIONS_QUERY, {
    variables: {
      userId,
    },
  });

  const companyId = R.pathOr('', ['user', 'company', 'id'], integrations) as string;

  const {
    data: saaSScoreResult,
    loading: metricsListLoading,
    refetch: refetchMetricsList,
  } = useQuery<EventResolverQueryQuery, EventResolverQueryQueryVariables>(EVENT_RESOLVER_QUERY, {
    variables: {
      event: {
        type: EventType.GetSaaSScoreResults,
        payload: { companyId },
      },
    },
    skip: !companyId,
  });

  const [
    calculateSaaSLoanOptions,
    { data: optionsData, loading: optionsLoading },
  ] = useSaaSLoanOptions(companyId);

  const [recalculateMetrics] = useCalculateMetrics(companyId);

  const { saaSScoreValue, saaSScoreTier, saaSScoreTierBands, metricsValues } =
    saaSScoreResult?.eventResolverQuery?.response || {};

  const [
    updateMetrics,
    { loading: isPending, error: isError, disabled: isDisabled },
  ] = useMetricsUpdate(companyId, recalculateMetrics, {
    onCompleted: async () => {
      await refetchMetricsList();
    },
  });

  const [progress, setProgress] = React.useState<number>(1);
  useInterval(
    () => {
      setProgress(progress => {
        // To make progress more natural we use random increment between 0 and 2
        const nextProgress = progress + Math.round(Math.random() * 2);

        if (nextProgress > PROGRESS_LIMIT) {
          return PROGRESS_LIMIT;
        }

        return nextProgress;
      });
    },
    isPending && progress < PROGRESS_LIMIT ? PLANNED_TIME * 5 : null,
  );

  const isRunned = useRef(false);

  const isLoading = userLoading || metricsListLoading || integrationsLoading;

  const saasScoreIsEmpty = R.isNil(saaSScoreValue);
  const optionsDataIsEmpty = R.isNil(optionsData) || R.isEmpty(optionsData);

  useEffect(() => {
    if (!isLoading && saasScoreIsEmpty && !isDisabled && !isRunned.current) {
      updateMetrics();
      setProgress(0);
      isRunned.current = true;
    }
  }, [isLoading, saasScoreIsEmpty, isDisabled, updateMetrics, setProgress]);

  useEffect(() => {
    if (!isPending && !saasScoreIsEmpty) {
      calculateSaaSLoanOptions();
    }
  }, [calculateSaaSLoanOptions, isPending, saasScoreIsEmpty]);

  const handleRetry = useCallback(() => {
    if (!isLoading && saasScoreIsEmpty && !isDisabled) {
      updateMetrics();
      setProgress(0);
    }
  }, [isLoading, saasScoreIsEmpty, isDisabled, updateMetrics, setProgress]);

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={12} item>
          <Header isReadyToFinalize={true} companyId={companyId} />
        </Grid>
        <Grid xs={12} md={7} item>
          <ScoringDetails
            progress={progress}
            saasScoreValue={saaSScoreValue}
            tierValue={saaSScoreTier}
            scoreDetailsMetrics={metricsValues || []}
            isError={isError}
            isPending={isPending}
            handleRetry={handleRetry}
          />
        </Grid>
        <Grid xs={12} md={5} item>
          <ScoringChart bounds={saaSScoreTierBands?.bounds || []} />
        </Grid>
      </Grid>

      <Grid container className={classes.saasLoan}>
        <Typography className={classes.heading}>Your SaaSLoan Options</Typography>

        <Grid item container className={classes.loanContainer} xs={12} direction="row">
          {!optionsDataIsEmpty ? (
            optionsData.map(option => <SaaSLoanCard key={option.term} option={option} />)
          ) : isPending || optionsLoading ? (
            <Grid item container className={classes.loaderWrapper} xs={12}>
              <CircularProgress className={classes.loader} />
              <Typography color="textSecondary" variant="body1" align="center">
                Loading...
              </Typography>
            </Grid>
          ) : (
            <EmptyScreen
              text="SaaSLoan options are not available for your company yet"
              icon={FormatListBulleted}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
