import * as R from 'ramda';
import { Settings, DateTime, DurationObject, DurationUnit } from 'luxon';
import pluralize from 'pluralize';

import { Locale } from 'src/constants';

export enum DateFormatPatterns {
  smallDateWithLocalTime = 'LLL dd – t',
  shortDateWithSlash = 'MM/dd/yyyy',
  shortDateWithDash = 'yyyy-MM-dd',
  fullDateStartsFromMonth = 'LLL. dd, yyyy',
  fullDateWithTime = 'dd-MM-yy__HH_mm',
  fullMonth = 'LLLL',
  fullMonthAndYear = 'LLLL, yyyy',
}

Settings.defaultLocale = Locale.EN;
Settings.defaultZoneName = 'Greenwich';

export function isValidDate(date: string | undefined | null): boolean {
  if (R.isNil(date)) {
    return false;
  }

  return DateTime.fromISO(date).isValid;
}

export function formatDate(
  date: string | undefined | null,
  toFormatPattern: string,
  fromFormatPattern?: string,
): string | null {
  if (R.isNil(date)) {
    return null;
  }

  if (R.isNil(fromFormatPattern)) {
    return DateTime.fromISO(date).toFormat(toFormatPattern);
  }

  return DateTime.fromFormat(date, fromFormatPattern).toFormat(toFormatPattern);
}

export function fromFormatToISO(
  date: string | null | undefined,
  format: string
): string | null {
  if (R.isNil(date)) {
    return null;
  }

  if (R.isNil(format)) {
    return null;
  }

  return DateTime.fromFormat(date, format).toISO();
}

export function addDate(date: string, unit: DurationUnit = 'month', count = 1): string {
  return DateTime.fromISO(date)
    .plus({ [unit]: count })
    .toISODate();
}

export function subtractDate(
  date: string | null | undefined,
  unit: DurationUnit = 'month',
  count = 1,
): string | null {
  if (R.isNil(date)) {
    return null;
  }

  return DateTime.fromISO(date)
    .minus({ [unit]: count })
    .toISODate();
}

export function getDateTimeEndOf(unit: DurationUnit, date?: string | null): DateTime {
  if (R.isNil(date)) {
    return DateTime.local().endOf(unit);
  }

  return DateTime.fromISO(date).endOf(unit);
}

export function getDateTimeStartOf(unit: DurationUnit, date?: string | null): DateTime {
  if (R.isNil(date)) {
    return DateTime.local().startOf(unit);
  }

  return DateTime.fromISO(date).startOf(unit);
}

export function getMonth(date: string, unit: 'number' | 'short' | 'long'): number | string | null {
  if (R.isNil(date)) {
    return null;
  }

  const dateTime = DateTime.fromISO(date);

  switch (unit) {
    case 'number':
      return dateTime.month;
    case 'short':
      return dateTime.monthShort;
    case 'long':
      return dateTime.monthLong;
    default:
      return dateTime.month;
  }
}

export const formatMonths = (months?: number | null | undefined): string | null =>
  !R.isNil(months) && Number.isFinite(months)
    ? `${months.toFixed(0)} ${pluralize('month', months)}`
    : null;

export const getNowDate = (): string => {
  return DateTime.local().toISODate();
};

export const getNowDateTime = (): string => {
  return DateTime.local().toISO();
};

export const getEndOfWeek = (date: string): string => {
  return DateTime.fromISO(date)
    .endOf('week')
    .toISODate();
};

export const getStartOfMonth = (date: string): string => {
  return DateTime.fromISO(date)
    .startOf('month')
    .toISODate();
};

export const getEndOfMonth = (date: string): string => {
  return DateTime.fromISO(date)
    .endOf('month')
    .toISODate();
};

export const getEndOfPreviousMonth = (date: string): string => {
  return DateTime.fromISO(date)
    .startOf('month')
    .minus({ months: 1 })
    .endOf('month')
    .toISODate();
};

export function getDifferenceBetweenTwoDates(
  from: string,
  till: string,
  timeUnit: DurationUnit,
): DurationObject {
  return DateTime.fromISO(till)
    .diff(DateTime.fromISO(from), [timeUnit])
    .toObject();
}

export function isDateEquals(
  d1: string | null | undefined,
  d2: string | null | undefined,
  unit: DurationUnit = 'day',
): boolean {
  if (R.isNil(d1) || R.isNil(d2)) {
    return false;
  }

  const dateA = DateTime.fromISO(d1).startOf(unit);
  const dateB = DateTime.fromISO(d2).startOf(unit);

  return dateA.equals(dateB);
}

export const isDateGreaterThan = (dateA: string, dateB: string): boolean => {
  return DateTime.fromISO(dateA) > DateTime.fromISO(dateB);
};

export const isDateGreaterOrEquals = (dateA: string, dateB: string): boolean => {
  return DateTime.fromISO(dateA) >= DateTime.fromISO(dateB);
};

export function isBetweenTwoDates(from: string, till: string, compareDate?: string): boolean {
  if (R.isNil(from) || R.isNil(till)) {
    return false;
  }

  const compareDateTime = R.isNil(compareDate) ? DateTime.local() : DateTime.fromISO(compareDate);
  const fromDateTime = DateTime.fromISO(from);
  const tillDateTime = DateTime.fromISO(till);

  return compareDateTime > fromDateTime && compareDateTime < tillDateTime;
}

export const getDateRange: (period: number, timeUnit?: DurationUnit) => string[] = (
  period: number,
  timeUnit = 'month',
): string[] =>
  Array(period)
    .fill(DateTime.local())
    .map((date: DateTime, i) =>
      date
        .minus({ [timeUnit]: period - i })
        .endOf(timeUnit)
        .toISODate(),
    );

export function getDateRangeBetweenTwoDates(
  from: string,
  till: string,
  timeUnit: DurationUnit,
): string[] {
  const diff = getDifferenceBetweenTwoDates(from, till, timeUnit);
  const [key] = R.keys(diff);
  const countUnits = Math.abs(Math.ceil(R.pathOr(0, [key], diff)));

  return Array(countUnits)
    .fill(DateTime.fromISO(till))
    .map((date: any, i) =>
      date
        .minus({ [timeUnit]: countUnits - i })
        .endOf(timeUnit)
        .toISODate(),
    );
}

export function getShortDate(date: string): string {
  return formatDate(date, DateFormatPatterns.shortDateWithDash) as string;
}

export function getTime(date: string): number {
  return isValidDate(date) ? DateTime.fromISO(date).toMillis() : 0;
};
