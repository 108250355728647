import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Theme,
  Checkbox,
  Typography,
} from '@material-ui/core';
import { useMutation } from 'react-apollo';
import { useModal, Form, FormSpy, Field, FileField } from 'src/app-builder';
import { useClientRoles } from 'src/app-builder/providers';

import { useNotification } from 'src/hooks';
import { SubmitButton } from 'src/components';
import {
  DOCUMENTS_CREATE_MANY_MUTATION,
  FILE_CREATE_MUTATION,
  File,
  MutationDocumentCreateManyArgs,
  DocumentManyResponse,
  MutationFileCreateArgs,
} from 'src/graphql';
import { DIALOGS } from './constants';
import { APP_ROLES } from 'src/types';
import { isFunction, t, connect, asyncMap } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(80),

    '& .MuiFormControl-root:not(:first-child)': {
      marginTop: theme.spacing(2),
    },
  },
  privateField: {
    display: 'flex',
    alignItems: 'center',
  },
  fileName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  fileNameBlock: {
    marginBottom: '5px',
    width: '50%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

type FilePrivate = File & {
  isPrivate: boolean;
};
type Files = Array<FilePrivate>;

interface DocumentCreateDialogProps {}

export const DocumentCreateDialog: React.FC<DocumentCreateDialogProps> = () => {
  const classes = useStyles();

  const notification = useNotification();

  const { open, closeModal, args, openModal } = useModal(DIALOGS.DOCUMENT_CREATE_DIALOG);

  const [files, setFiles] = React.useState<Files>([]);

  const [documentCreateMany] = useMutation<DocumentManyResponse, MutationDocumentCreateManyArgs>(
    DOCUMENTS_CREATE_MANY_MUTATION,
  );
  const [fileCreate] = useMutation<{ fileCreate: File }, MutationFileCreateArgs>(
    FILE_CREATE_MUTATION,
  );

  const { currentRole } = useClientRoles();

  const isBigfootEmployee =
    currentRole?.name === APP_ROLES.appAdministrator.name ||
    currentRole?.name === APP_ROLES.appAnalyst.name;

  const isCompany = Boolean(args?.companyId);
  const isLoanApplication = Boolean(args?.loanApplicationId);

  const initialValues = isBigfootEmployee ? [] : {};

  const onInitFiles = (values: any) => {
    const files = values?.file || [];

    const initFiles = files?.map((file: File) => ({
      ...file,
      isPrivate: false,
    }));

    setFiles(initFiles);
  };

  const connectToCompanyOrLoanApp = (data: any) => {
    if (isCompany) {
      return connect(data).to('company', args.companyId);
    }

    if (isLoanApplication) {
      connect(data).to('loanApplication', args.loanApplicationId);
    }

    return {};
  };

  const onTogglePrivateFile = (event: React.ChangeEvent<HTMLInputElement>, fileId: string) => {
    const isPrivate = event.target.checked;

    setFiles(files => {
      return files.map(file => {
        if (file.fileId === fileId) {
          return {
            ...file,
            isPrivate,
          };
        }

        return file;
      });
    });
  };

  const createFiles = async (): Promise<Array<FilePrivate>> => {
    const createdFiles = await asyncMap<FilePrivate, FilePrivate>(files, async file => {
      const fileData = {
        fileId: file.fileId,
        filename: file.filename,
        public: file.public,
      };

      try {
        const { data } = await fileCreate({
          variables: {
            data: fileData,
          },
        });

        const createdFile = data?.fileCreate || {};

        return {
          ...createdFile,
          isPrivate: file.isPrivate,
        };
      } catch (error) {
        notification.error(t('file_create_error', { filename: file.filename as string }));
        throw error;
      }
    });

    return createdFiles;
  };

  const onSubmit = React.useCallback(
    async (data, form) => {
      try {
        const createdFiles = await createFiles();

        const documentCreateManyData = createdFiles.map(createdFile => {
          const { isPrivate } = createdFile;
          const file = {
            connect: {
              id: createdFile.id,
            },
          };
          const connectFileToCompany = connectToCompanyOrLoanApp({ file, private: isPrivate });

          return connectFileToCompany;
        });
        await documentCreateMany({
          variables: {
            data: documentCreateManyData as any,
          },
        });

        notification.success(t('document_create_success'));
        closeModal();

        setTimeout(form.reset);

        if (isFunction(args?.onSuccess)) {
          args.onSuccess();
        }
      } catch (error) {
        notification.error(t('document_create_error'));
        console.error({ error });
      }
    },
    [closeModal, documentCreateMany, fileCreate, notification, args, files],
  );

  const onClose = React.useCallback(
    (form: any, pristine: boolean) => {
      if (!pristine) {
        openModal(DIALOGS.DATA_LOSS_DIALOG, {
          onConfirm: () => {
            closeModal();

            setTimeout(form.reset);
          },
        });
      } else {
        closeModal();

        setTimeout(form.reset);
      }
    },
    [closeModal, openModal],
  );

  if (!open) {
    return null;
  }

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, form, submitting, pristine }): React.ReactNode => (
        <Dialog
          disableRestoreFocus
          open={open}
          onClose={() => onClose(form, pristine)}
          PaperProps={{
            className: classes.root,
            component: 'form',
            onSubmit: handleSubmit,
          }}
          maxWidth="md"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Add Document</Typography>
          </DialogTitle>
          <DialogContent>
            <FormSpy
              subscription={{ values: true }}
              onChange={({ values }) => onInitFiles(values)}
            />
            {files.map((file: any) => {
              return (
                <div className={classes.fileName} key={file.fileId}>
                  <Typography className={classes.fileNameBlock}>{file.filename}</Typography>
                  {isBigfootEmployee && (
                    <div className={classes.privateField}>
                      <Typography>Make Private</Typography>
                      <Checkbox
                        checked={file.isPrivate}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          onTogglePrivateFile(event, file.fileId)
                        }
                      />
                    </div>
                  )}
                </div>
              );
            })}
            <Field
              validate={v => (v ? null : 'File is required')}
              name="file"
              label="File"
              component={FileField}
              fullWidth
              disabled={submitting}
              sessionCache
            />
          </DialogContent>

          <DialogActions>
            <Button color="secondary" onClick={() => onClose(form, pristine)} disabled={submitting}>
              Cancel
            </Button>
            <SubmitButton
              color="secondary"
              variant="contained"
              type="submit"
              disabled={pristine}
              loading={submitting}
            >
              Add Document
            </SubmitButton>
          </DialogActions>
        </Dialog>
      )}
    </Form>
  );
};
