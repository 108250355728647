import type { EmptyObject, Merge } from 'src/types';

type ConnectToEntity = {
  [entityKey: string]: {
    connect: {
      id: string;
    }
  }
}
type ConnectedEntity<TData> = Merge<TData, ConnectToEntity>;
type ConnectResult<TData> = {
  to: (entityKey: string, entityId: string) =>  ConnectedEntity<TData>
};

export function connect<TData = EmptyObject>(connectData: TData): ConnectResult<TData> {
  const to = (entityKey: string, entityId: string) => {
    return {
      [entityKey]: {
        connect: {
          id: entityId,
        }
      },
      ...connectData,
    }
  };

  return { to };
};
